/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import LanguageContext from './LanguageContext.js';
import PageWrapper from './PageWrapper.js';
import i18n from './src/i18n';
import 'normalize.css';
import './src/styles/styles.scss';

export const wrapPageElement = ({ element }) => {
  return (
    <PageWrapper>
      <I18nextProvider i18n={i18n}>
        <LanguageContext>{element}</LanguageContext>
      </I18nextProvider>
    </PageWrapper>
  );
};