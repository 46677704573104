import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';
import { ALL_LOCALES, LANG_DEFAULT, LANG_COOKIE } from './constants';

/**
 * Wrapper for root element that checks the page path and updates the 'i18n' singleton
 * with the current language. i.e. the language prefix in the page determines the
 * language to show.
 *
 * If NO language prefix is in the pathname, then change the window location to the appropriate location.
 * 
 * WARNING: There is a lot of defensive code (e.g. typeof window !== 'undefined') so that this component
 * runs fine during the gatsby build.
 */
const LanguageContext = ({ children }) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const path = location.pathname;
  let localePrefix = null;
  if (path.search(/^[/][a-z]{2}-[A-Z]{2}/) === 0) {
    localePrefix = path.slice(1, 6);
    // If the extracted localePrefix is not recognized, reset it to null
    if (ALL_LOCALES.indexOf(localePrefix) == -1) {
      localePrefix = null;
    }
  }

  let cookieValue = null;
  if (typeof document != 'undefined') {
    const cookieRegex = new RegExp(`${LANG_COOKIE}`);
    const cepLangCookie = document.cookie
      ? document.cookie
          .split(';')
          .find((cookie) => cookie.search(cookieRegex) > -1) || ''
      : '';
    const cepLangCookieValueMatch = cepLangCookie.match(
      /.*[=]\s*([a-z]{2}-[A-Z]{2})/
    );

    if (cepLangCookieValueMatch != null) {
      cookieValue = cepLangCookieValueMatch[1];
    }
  }

  if (localePrefix) {
    if (typeof document !== 'undefined' && !(cookieValue === localePrefix)) {
      document.cookie = `${LANG_COOKIE}=${localePrefix};path=/`;
    }
    if (!(localePrefix === i18n.language)) {
      // This should rarely trigger, only when switching languages
      i18n.changeLanguage(localePrefix);
    }
  } else {
    // Update path if no prefix detected.
    if (typeof window !== 'undefined') {
      if (cookieValue) {
        window.location.pathname = '/' + cookieValue + path;
      } else {
        window.location.pathname = `/${LANG_DEFAULT}` + path;
      }
    } else {
      // We should only reach this step during the build!
      i18n.changeLanguage(LANG_DEFAULT);
    }
  }

  return <>{children}</>;
};

export default LanguageContext;
