import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANG_DEFAULT, ALL_LOCALES } from '../constants';

const pages = [
  'home',
  'cybersecurity',
  'common',
  'subscribe',
  'about',
  'classroom-resources',
  'video-topic-series',
  'career-profiles',
];

const resources = {};
pages.forEach((page) => {
  ALL_LOCALES.forEach((lang) => {
    let translation = require(`./locales/${page}/${lang}.json`);

    if (!resources[lang]) {
      resources[lang] = {};
    }
    resources[lang][page] = {
      ...translation,
    };
  });
});

i18n
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: LANG_DEFAULT,
    keySeparator: '^',
    returnObjects: true,
    resources,
    react: {
      useSuspense: false,
    },
  })
  .then();

i18n.loadNamespaces(pages);

export default i18n;
