module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/Users/KCrisanto/Documents/girs-4-tech-canada/code/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd402a40c94c4fb070847e8331d20eee"},
    },{
      plugin: require('../node_modules/@discoveryed/de-analytics/gatsby-browser.js'),
      options: {"plugins":[],"extraProdHostnames":["girls4tech.discoveryed.ca"],"partnershipId":"c092e060-5577-4524-b3ae-ad2cd3ec4b97","region":"CA"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
